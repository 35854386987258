/* eslint-disable */

const FUTURE_TS_THRESHOLD = 3; // second
const PAST_TS_THRESHOLD = 10; // count
const NO_MSG_THRESHOLD = 10000; // millisecond
const LONG_DELAY_THRESHOLD = 12; // second; must be greater than LONG_DELAY_REDUCTION
const LONG_DELAY_REDUCTION = 3; // second

const JP_PLATE_NUMBER_REGION = [
    "(無)", "品川", "世田谷", "練馬", "杉並", "板橋", "足立", "江東", "葛飾", "八王子", 
    "多摩", "横浜", "川崎", "湘南", "相模", "大宮", "川口", "所沢", "川越", "熊谷", 
    "春日部", "越谷", "千葉", "成田", "習志野", "市川", "船橋", "袖ヶ浦", "市原", "野田", 
    "柏", "松戸", "名古屋", "豊橋", "三河", "岡崎", "豊田", "尾張小牧", "一宮", "春日井", 
    "水戸", "土浦", "つくば", "宇都宮", "那須", "とちぎ", "群馬", "前橋", "高崎", "山梨", 
    "富士山", "札幌", "函館", "旭川", "室蘭", "苫小牧", "釧路", "知床", "帯広", "北見", 
    "青森", "弘前", "八戸", "岩手", "盛岡", "平泉", "宮城", "仙台", "秋田", "山形", 
    "庄内", "福島", "会津", "郡山", "白河", "いわき", "新潟", "長岡", "上越", "長野", 
    "松本", "諏訪", "富山", "石川", "金沢", "福井", "岐阜", "飛騨", "静岡", "浜松", 
    "沼津", "伊豆", "三重", "鈴鹿", "四日市", "伊勢志摩", "滋賀", "京都", "なにわ", "大阪", 
    "和泉", "堺", "奈良", "飛鳥", "和歌山", "神戸", "姫路", "鳥取", "島根", "出雲", 
    "岡山", "倉敷", "広島", "福山", "山口", "下関", "徳島", "香川", "高松", "愛媛", 
    "高知", "福岡", "北九州", "久留米", "筑豊", "佐賀", "長崎", "佐世保", "熊本", "大分", 
    "宮崎", "鹿児島", "奄美", "沖縄"
]
const JP_PLATE_NUMBER_HIRA = [
    "(無)", "あ", "い", "う", "え", "を", "か", "き", "く", "け", 
    "こ", "さ", "す", "せ", "そ", "た", "ち", "つ", "て", "と", 
    "な", "に", "ぬ", "ね", "の", "は", "ひ", "ふ", "ほ", "ま", 
    "み", "む", "め", "も", "や", "ゆ", "よ", "ら", "り", "る", 
    "れ", "ろ", "わ"
]

class Mse {
    constructor(video, src, unsupportedHandler, resizeFunc) {

        const srcUrl = new URL(src);
        srcUrl.searchParams.set('extra-meta', 'true');
        srcUrl.searchParams.set('frag-interval', '150');
        this.target = srcUrl.searchParams.has('target') ? srcUrl.searchParams.get('target') : "";
        this.startupCount = 0;

        this.active = true;

        this._debug = 0;
        this.heartbeat = 0;
        this.streamingStarted = false;
        this.queue = [];
        this.ws = null;

        this.element = video;
        this.src = srcUrl.href;
        this.unsupportedHandler = unsupportedHandler;
        this.resizeFunc = resizeFunc;

        this.onailabel = null
        this.aiLabelSeq = 0
        this.prevFrameWithAiLabel = false
        // this.lastAiLabelPromise = null

        this.mediaSource = new MediaSource();
        this.onMediaSourceOpen = () => this.opened()
        this.onSourceBufferUpdateEnd = () => this.loadPacket()

        this.sourceBuffer = null;
        this.mimeCodec;
        this.lastVideoInputFPS;

        this.streamInfo = new StreamInfo();
        this.lastCalculateTime = 0;

        this.tryToRestart = false;
        this.tryToReplay = false;
        this.prevEndTime = 0; // Log the last end time after data was appended to the source buffer
        this.lastWsTime = 0; // Log the last time a message was received or a connection was opened
        this.futureTsErr = 0; // Error count for future timestamps
        this.pastTsErr = 0; // Error count for past timestamps
        this.noMsgErr = 0; // Error count of messages not received within the specified period
        this.noConnErr = 0; // Error count for unexpectedly closing connections

        // if (!Uint8Array.prototype.slice) {
        //     Object.defineProperty(Uint8Array.prototype, 'slice', {
        //         value: function (begin, end) {
        //             return new Uint8Array(Array.prototype.slice.call(this, begin, end));
        //         }
        //     });
        // }
    }

    get debug() {
        return (this.element && this.element.dataset.mseDebug) ? Number(this.element.dataset.mseDebug) : Number(this._debug)
    }

    pushPacket(arr) {
        var view = new Uint8Array(arr);
        if (this.debug > 1) {
            console.log('got', arr.byteLength, 'bytes.  Values=', view[0], view[1], view[2], view[3], view[4]);
        }
        const data = arr;
        if (!this.streamingStarted) {
            try {
                this.sourceBuffer.appendBuffer(data);
                this.streamingStarted = true;

            } catch (e) {
                this.debug && console.log(`[${this.target}#${this.startupCount}] active = ${this.active}, error name = ${e.name}`);
                if (e.name === 'QuotaExceededError' && this.active) {
                    this.debug && console.error(e);
                    this.restart();
                } else {
                    // e.name === 'InvalidStateError'
                    this.close();
                    this.element.setAttribute('error', 'true');
                }
            }
            return;
        }
        this.queue.push(data);

        if (this.debug > 1) {
            console.log('queue push:', this.queue.length);
        }
        if (!this.sourceBuffer.updating) {
            this.loadPacket();
        }
    };

    loadPacket() {
        this.heartbeat = 0;

        if (!this.sourceBuffer.updating) {
            let bufferedLen = this.sourceBuffer.buffered.length;
            if (this.sourceBuffer.buffered.length > 0) {
                // Check the end time of the buffer to make sure the video segment has the correct timestamp
                if (this.prevEndTime <= 0) {
                    this.prevEndTime = this.sourceBuffer.buffered.end(bufferedLen - 1);
                } else if ((this.sourceBuffer.buffered.end(bufferedLen - 1) - this.prevEndTime) > FUTURE_TS_THRESHOLD) {
                    // If the segment duration is under 3 seconds, the end time should not be far
                    this.futureTsErr++;
                    this.debug && console.warn(`[${this.target}#${this.startupCount}] ts is in the future (${this.futureTsErr})`);
                } else if (this.futureTsErr == 0 && this.sourceBuffer.buffered.end(bufferedLen - 1) == this.prevEndTime) {
                    // End time not updated; assuming ts is too old and exceeds append window
                    // But when the appended data is an audio sample, the end time is also the same
                    this.pastTsErr++;
                    this.debug && this.pastTsErr > PAST_TS_THRESHOLD && console.warn(`[${this.target}#${this.startupCount}] ts is too old (${this.pastTsErr})`);
                } else {
                    this.pastTsErr = 0;
                }
                this.prevEndTime = this.sourceBuffer.buffered.end(bufferedLen - 1);
            }
            if (this.queue.length > 0) {
                const inp = this.queue.shift();
                if (this.debug > 1) {
                    console.log('queue PULL:', this.queue.length);
                }
                var view = new Uint8Array(inp);
                if (this.debug > 1) {
                    console.log('writing buffer with', view[0], view[1], view[2], view[3], view[4]);
                }
                try {
                    this.sourceBuffer.appendBuffer(inp);
                    this.streamingStarted = true;
                } catch (e) {
                    // this.close();
                    // this.element.setAttribute('error', 'true');
                    this.debug && console.error(e);
                    this.restart();
                }
            } else {
                this.streamingStarted = false;
            }
        }
        //this.sourceBuffer.removeEventListener('updateend', this.loadPacket)
    };

    opened() {
        // Tracy 2021.12.30 先呼叫URL.revokeObjectURL()使其在沒有被用到時記憶體可以被回收 https://developers.google.com/web/fundamentals/media/mse/basics
        //URL.revokeObjectURL(this.element.src)
        if (this.tryToReplay) {
            this.debug && console.log(`[${this.target}#${this.startupCount}] >>> Replay <<<`);
            try {
                this.element.play();
            } catch (e) {
                this.debug && console.error(e);
            }
        }
        this.streamingStarted = false;
        this.queue = [];
        if (this.ws) {
            console.warn("Close a previously unclosed WebSocket connection")
            this.ws.onclose = function () {
                this.debug && console.log(`[${this.target}#${this.startupCount}] ${this.src} Closed`);
            }.bind(this);
            this.ws.close()
        }
        this.ws = new WebSocket(this.src);
        this.ws.binaryType = 'arraybuffer';
        this.ws.onopen = function (event) {
            if (this.debug) {
                console.log(`[${this.target}#${this.startupCount}] Connect ${this.src}`);
            }
            this.lastWsTime = Date.now();
            this.resetDataRateCalculators();
        }.bind(this);
        this.ws.onmessage = function (event) {
            this.lastWsTime = Date.now();
            if (this.debug > 1) {
                console.log(`(${this.lastWsTime}) receive ${event.data.byteLength} bytes`);
            }
            this.streamInfo.recordDataReceived(event.data.byteLength, this.lastWsTime)

            let dataBuffer = event.data;
            let data = new Uint8Array(dataBuffer);

            // If the signature "BV" is found, process the extra metadata in front and filter it out
            if (data.length >= 2 && data[0] === 'B'.charCodeAt(0) && data[1] === 'V'.charCodeAt(0)) {
                dataBuffer = this.processExtraMeta(dataBuffer.slice(2));
                data = new Uint8Array(dataBuffer);
            }

            if (data.length > 0 && data[0] == 9) {
                this.createSourceBuffer(data.slice(1));
            } else {
                this.pushPacket(dataBuffer);
            }
        }.bind(this);
        this.ws.onclose = function () {
            this.debug && console.log(`[${this.target}#${this.startupCount}] ${this.src} Closed`);
            if (this.active) {
                this.noConnErr++;
                this.debug && console.warn(`[${this.target}#${this.startupCount}] ws is closed unexpectedly (${this.noConnErr})`);
            }
        }.bind(this);
    };

    processExtraMeta(dataBuffer) {
        // dataBuffer starts with 2 bytes metadata length
        let extraMetaLen = new DataView(dataBuffer).getUint16(0);
        if (extraMetaLen > 0) {
            let extraMeta = new Uint8Array(dataBuffer.slice(2, 2 + extraMetaLen));
            let extraMetaType = extraMeta[0];
            let extraMetaPayload = extraMeta.slice(1);

            if (extraMetaType == 0) {
                let jsonString = Utf8ArrayToStr(extraMetaPayload);
                (this.debug > 1) && console.log(`extra-meta: ${jsonString}`);
                try {
                    let jsonObject = JSON.parse(jsonString);
                    if ('FPS' in jsonObject) {
                        this.lastVideoInputFPS = jsonObject.FPS;
                    }
                    const { codec, frag } = jsonObject || {}
                    if (codec) {
                        this.createSourceBuffer(codec);
                    }
                    this.streamInfo.recordMetaReceived(jsonObject)

                    const { ext } = frag || {}
                    if (ext) {
                        ext.forEach((v) => {
                            let extBytes = new Uint8Array(Array.from(atob(v), c => c.charCodeAt(0)));
                            this.parseExtension(extBytes)
                        }, this)
                    } else {
                        this._onAiLabel() // notification currently has no AI-Label
                    }
                } catch (error) {
                    console.error(error);
                }
            } else if (extraMetaType == 9) {
                this.createSourceBuffer(extraMetaPayload);
            }
        }

        if (this.debug > 1) {
            console.log(`(${this.lastWsTime}) skip ${2 + extraMetaLen} bytes to filter out extra metadata`);
        }
        // Filter out extra metadata
        return dataBuffer.slice(2 + extraMetaLen);
    };

    parseExtension(extBytes) {
        if (extBytes.byteLength < 4) return

        // Marker for Bovia
        const Marker = {
            b: 'b'.charCodeAt(0),
            v: 'v'.charCodeAt(0)
        }

        // Object type
        const ObjectType = {
            LPR: 1,
            FR: 2,
            OR: 3,
            NoiseCar: 4,
            QosServer: 16
        }

        const trimEndZeros = (uint8Array) => {
            let lastIndex = uint8Array.length - 1;
            while (lastIndex >= 0 && uint8Array[lastIndex] === 0) {
                lastIndex--;
            }
            return uint8Array.subarray(0, lastIndex + 1);
        }

        const allAiLabels = []
        let offset = 0
        while (offset <= (extBytes.byteLength - 4)) {
            // console.log(`Ext: offset=${offset}, data=${extBytes.subarray(offset)}`)
            const extRemaingByteLength = extBytes.byteLength - offset
            const extHeader = new DataView(extBytes.buffer, offset, 4)
            const marker0 = extHeader.getUint8(0)
            const marker1 = extHeader.getUint8(1)
            const payloadByteLength = extHeader.getUint16(2) * 4
            offset += 4
            if (this.debug > 1) {
                console.log(`ExtHeader: marker=${String.fromCharCode(marker0, marker1)}, byteLen=${payloadByteLength}`)
            }

            if (marker0 === Marker.b && marker1 === Marker.v && payloadByteLength <= (extRemaingByteLength - 4)) {
                let payloadOffset = 0
                while (payloadOffset <= (payloadByteLength - 4)) {
                    // console.log(`Obj: offset=${payloadOffset}, data=${extBytes.subarray(offset + payloadOffset, offset + payloadByteLength)}`)
                    const objRemaingByteLength = payloadByteLength - payloadOffset
                    const objHeader = new DataView(extBytes.buffer, (offset + payloadOffset), 4)
                    const objByteLength = objHeader.getUint8(0) * 4
                    const objType = objHeader.getUint8(1)
                    const objSubType = objHeader.getUint16(2)
                    payloadOffset += 4
                    if (this.debug > 1) {
                        console.log(`ObjHeader: byteLen=${objByteLength}, type=${objType}, sub=${objSubType}`)
                    }

                    if (objByteLength >= 4 && objByteLength <= objRemaingByteLength) {
                        const objBody = new DataView(extBytes.buffer, (offset + payloadOffset), (objByteLength - 4))

                        let objBodyOffset = 0
                        const objBodyGetFloat64 = (keepOffset = false) => {
                            const v = objBody.getFloat64(objBodyOffset)
                            if (!keepOffset) objBodyOffset += 8
                            return v
                        }
                        const objBodyGetFloat32 = (keepOffset = false) => {
                            const v = objBody.getFloat32(objBodyOffset)
                            if (!keepOffset) objBodyOffset += 4
                            return v
                        }
                        const objBodyGetUint32 = (keepOffset = false) => {
                            const v = objBody.getUint32(objBodyOffset)
                            if (!keepOffset) objBodyOffset += 4
                            return v
                        }
                        const objBodyGetUint16 = (keepOffset = false) => {
                            const v = objBody.getUint16(objBodyOffset)
                            if (!keepOffset) objBodyOffset += 2
                            return v
                        }
                        const objBodyGetUint8Array = (length, keepOffset = false) => {
                            const v = extBytes.subarray(offset + payloadOffset + objBodyOffset, offset + payloadOffset + objBodyOffset + length)
                            if (!keepOffset) objBodyOffset += length
                            return v
                        }

                        try {
                            if (objType == ObjectType.LPR || objType == ObjectType.FR || objType == ObjectType.OR) {
                                const tlX = objBodyGetFloat64()
                                const tlY = objBodyGetFloat64()
                                const width = objBodyGetFloat64()
                                const height = objBodyGetFloat64()
                                this.debug && console.log(`LPR/FR/OR: tlx=${tlX}, tly=${tlY}, w=${width}, h=${height}`)

                                const aiLabel = {
                                    seq: this.aiLabelSeq++,
                                    tlX: tlX,
                                    tlY: tlY,
                                    width: width,
                                    height: height
                                }

                                if (objType == ObjectType.LPR) {
                                    aiLabel.type = 'LPR'

                                    if (objSubType & 0x0001) {
                                        const matched = objBodyGetUint32()
                                        aiLabel.matched = matched
                                        this.debug && console.log(`LPR: matched=${matched}`)
                                    }
                                    if (objSubType & 0x0002) {
                                        const id = objBodyGetUint8Array(20)
                                        aiLabel.id = Utf8ArrayToStr(trimEndZeros(id))
                                        this.debug && console.log(`LPR: id=${aiLabel.id}(${id})`)
                                    }
                                    if (objSubType & 0x0004) {
                                        const twPlateNumber = objBodyGetUint8Array(8)
                                        objBodyOffset += 4
                                        aiLabel.twPlateNumber = Utf8ArrayToStr(trimEndZeros(twPlateNumber))
                                        this.debug && console.log(`LPR: twPlateNumber=${aiLabel.twPlateNumber}(${twPlateNumber})`)
                                    }
                                    if (objSubType & 0x0008) {
                                        const jpRegion = objBodyGetUint8Array(1)[0]
                                        const jpCategory = objBodyGetUint8Array(3)
                                        const jpHira = objBodyGetUint8Array(1)[0]
                                        const jpNumber = objBodyGetUint8Array(4)
                                        objBodyOffset += 3

                                        const jpPlateNumber = []
                                        if (jpRegion > 1 && jpRegion < JP_PLATE_NUMBER_REGION.length) {
                                            jpPlateNumber.push(JP_PLATE_NUMBER_REGION[jpRegion])
                                        }
                                        jpPlateNumber.push(String((jpCategory[0] << 16) | (jpCategory[1] << 8) | jpCategory[2]))
                                        if (jpHira > 1 && jpHira < JP_PLATE_NUMBER_HIRA.length) {
                                            jpPlateNumber.push(JP_PLATE_NUMBER_HIRA[jpHira])
                                        }
                                        jpPlateNumber.push(Utf8ArrayToStr(trimEndZeros(jpNumber)))

                                        aiLabel.jpPlateNumber = jpPlateNumber.join(' ')
                                        this.debug && console.log(`LPR: jpPlateNumber=${aiLabel.jpPlateNumber}(${jpRegion}, ${jpCategory}, ${jpHira}, ${jpNumber})`)
                                    }
                                    if (objSubType & 0x0010) {
                                        const vnPlateNumber = objBodyGetUint8Array(9)
                                        objBodyOffset += 3
                                        aiLabel.vnPlateNumber = Utf8ArrayToStr(trimEndZeros(vnPlateNumber))
                                        this.debug && console.log(`LPR: vnPlateNumber=${aiLabel.vnPlateNumber}(${vnPlateNumber})`)
                                    }
                                    if (objSubType & 0x0020) {
                                        aiLabel.illegalEP = true
                                        this.debug && console.log(`LPR: illegal exhaust pipe`)
                                    }
                                    if (objSubType & 0x0080) {
                                        const decibel = objBodyGetFloat32()
                                        aiLabel.decibel = decibel
                                        this.debug && console.log(`LPR: decibel=${decibel}`)
                                    }
                                } else if (objType == ObjectType.FR) {
                                    aiLabel.type = 'FR'

                                    if (objSubType & 0x0001) {
                                        const matched = objBodyGetUint32()
                                        aiLabel.matched = matched
                                        this.debug && console.log(`FR: matched=${matched}`)
                                    }
                                    if (objSubType & 0x0002) {
                                        const id = objBodyGetUint8Array(20)
                                        aiLabel.id = Utf8ArrayToStr(trimEndZeros(id))
                                        this.debug && console.log(`FR: id=${aiLabel.id}(${id})`)
                                    }
                                    if (objSubType & 0x0004) {
                                        const name = objBodyGetUint8Array(20)
                                        aiLabel.name = Utf8ArrayToStr(trimEndZeros(name))
                                        this.debug && console.log(`FR: name=${aiLabel.name}(${name})`)
                                    }
                                    if (objSubType & 0x0008) {
                                        const score = objBodyGetFloat32()
                                        aiLabel.score = score
                                        this.debug && console.log(`FR: score=${score}`)
                                    }
                                } else if (objType == ObjectType.OR) {
                                    aiLabel.type = 'OR'                                    

                                    if (objSubType & 0x0001) {
                                        const objClass = objBodyGetUint32()
                                        aiLabel.objClass = objClass
                                        this.debug && console.log(`OR: objClass=${objClass}`)
                                    }
                                    if (objSubType & 0x0002) {
                                        const score = objBodyGetFloat32()
                                        aiLabel.score = score
                                        this.debug && console.log(`OR: score=${score}`)
                                    }
                                    if (objSubType & 0x0004) {
                                        aiLabel.motion = true
                                        this.debug && console.log(`OR: motion=${aiLabel.motion}`)
                                    }
                                }
                                // console.log('AI-Label:', aiLabel)
                                allAiLabels.push(aiLabel)
                            } else if (objType == ObjectType.NoiseCar) {
                                const aiLabel = {
                                    type: 'NoiseCar'
                                }
                                if (objSubType & 0x0001) {
                                    const decibel = objBodyGetFloat32()
                                    aiLabel.decibel = decibel
                                    this.debug && console.log(`NoiseCar: decibel=${decibel}`)
                                }
                                // console.log('AI-Label:', aiLabel)
                                allAiLabels.push(aiLabel)
                            } else if (objType == ObjectType.QosServer) {
                                const frameCnt = objBodyGetUint16()
                                const completeFrameCnt = objBodyGetUint16()
                                const receivedByte = objBodyGetUint32()
                                const durationTsMs = objBodyGetUint32()
                                const durationSystemTimeMs = objBodyGetUint32()
                                if (this.debug > 1) {
                                    console.log(`Qos-Server: frameCnt=${frameCnt}, completeFrameCnt=${completeFrameCnt}, receivedByte=${receivedByte}, durationTsMs=${durationTsMs}, durationSystemTimeMs=${durationSystemTimeMs}`)
                                }
                            }
                        } catch (error) {
                            console.warn('ObjBody: failed to read', error)
                        }
                    } else {
                        console.warn(`ObjHeader: incorrect length (obj=${objByteLength}, buf=${objRemaingByteLength})`)
                        if (objByteLength < 4) {
                            console.warn('ObjHeader: no header')
                            break
                        }
                    }

                    payloadOffset += objByteLength - 4
                }
            } else {
                console.warn(`ExtHeader: incorrect marker (${String.fromCharCode(marker0, marker1)}) or length (payload=${payloadByteLength}, buf=${extRemaingByteLength})`)
            }
            offset += payloadByteLength
        }

        this._onAiLabel(allAiLabels)
    }

    _onAiLabel(allAiLabels) {
        const hasAiLabel = (Array.isArray(allAiLabels)) && (allAiLabels.length > 0)
        if (this.debug > 1) {
            if (hasAiLabel) {
                if (allAiLabels.length > 1) {
                    console.log(`AI-Label: multiple labels (${allAiLabels.length})`)
                }
                allAiLabels.forEach((label) => console.log(`AI-Label #${label.seq}:`, label))
            } else if (this.prevFrameWithAiLabel) {
                console.log('AI-Label: no labels')
            }
        }

        if (this.onailabel && (hasAiLabel || this.prevFrameWithAiLabel)) {
            // this.lastAiLabelPromise = this.lastAiLabelPromise
            //     ? this.lastAiLabelPromise.finally(() => this.onailabel(allAiLabels))
            //     : new Promise((resolve) => {
            //         this.onailabel(hasAiLabel ? allAiLabels : [])
            //         resolve()
            //     })
            this.onailabel(hasAiLabel ? allAiLabels : [])
        }
        this.prevFrameWithAiLabel = hasAiLabel
    }

    createSourceBuffer(decoded_arr) {
        this.mimeCodec = (typeof(decoded_arr) === 'string') ? decoded_arr : Utf8ArrayToStr(decoded_arr)
        this.streamInfo.updateCodec(this.mimeCodec);
        if (this.debug) {
            console.log(`[${this.target}#${this.startupCount}] first packet with codec data: ${this.mimeCodec} (${this.streamInfo.codecs})`);
        }

        let mimestr = 'video/mp4; codecs="' + this.mimeCodec + '"';

        if (!MediaSource.isTypeSupported(mimestr)) {
            console.error(`Unsupported codec: ${mimestr}`);
            this.close();
            if (this.unsupportedHandler) this.unsupportedHandler(mimestr);
            return;
        }

        this.sourceBuffer = this.mediaSource.addSourceBuffer(mimestr);
        this.sourceBuffer.mode = 'segments';
        this.sourceBuffer.addEventListener('updateend', this.onSourceBufferUpdateEnd);

        if (this.resizeFunc) this.resizeFunc();
    };

    seeker() {

        if (this.element.buffered.length > 0 && !this.element.paused) {
            let threshold = this.element.buffered.end(0) - this.element.currentTime;
            // this.element.controls = false;

            /*
            if (threshold <= 0.5) {
                this.element.playbackRate = 0.1;
            } else if (threshold <= 1.0) {
                this.element.playbackRate = 0.5;
            } else if (threshold <= 2.0) {
                this.element.playbackRate = 1;
            } else if (threshold <= 3.0) {
                this.element.playbackRate = 1.5;
            } else {
                this.element.currentTime = this.element.buffered.end(0) - 1.5;
                this.element.playbackRate = 1;
            }*/
            var targetBuffer = 0.5;
            var tolerance = 0.1;
            if (threshold > LONG_DELAY_THRESHOLD) {
                // this.element.currentTime = this.element.buffered.end(0) - targetBuffer;
                // this.element.playbackRate = 1.0;
                this.debug && console.log(`[${this.target}#${this.startupCount}] long delay=${threshold}`);
                threshold = LONG_DELAY_REDUCTION;
                this.element.currentTime = this.element.buffered.end(0) - threshold;
            }
            {
                this.element.playbackRate = rateFunction(threshold, targetBuffer, tolerance);
                this.debug && threshold >= LONG_DELAY_REDUCTION && console.log(`[${this.target}#${this.startupCount}] delay=${threshold}, playbackRate=${this.element.playbackRate}`);
            }

            // this.element.controls = true;
        }

        this.active && window.setTimeout((() => this.seeker()), 200);
    };

    startup() {
        this.startupCount++;
        this.debug && console.log(`[${this.target}#${this.startupCount}] startup is called`);
        this.active = true;
        this.resetErrorChecking();
        this.mediaSource.addEventListener('sourceopen', this.onMediaSourceOpen, false);
        this.element.src = window.URL.createObjectURL(this.mediaSource);
        window.setTimeout((() => this.seeker()), 2000);
        window.setTimeout((() => this.errorChecker()), 1000);
    };

    close() {
        this.debug && console.log(`[${this.target}#${this.startupCount}] close is called`);
        this.tryToRestart = false;
        this.mediaSource.removeEventListener('sourceopen', this.onMediaSourceOpen, false);
        //this.element.src = '';
        this.active = false;
        this.ws.close();
        this.ws = null
        if (this.sourceBuffer) {
            this.sourceBuffer.removeEventListener('updateend', this.onSourceBufferUpdateEnd, false)
            this.mediaSource.removeSourceBuffer(this.sourceBuffer)
            this.sourceBuffer = null
        }
    };

    restart(forceReplay) {
        try {
            this.tryToReplay = (!this.element.paused) || forceReplay;
            this.close();
            this.tryToRestart = true;
            window.setTimeout((() => {
                if (this.tryToRestart) {
                    this.debug && console.log(`[${this.target}#${this.startupCount}] >>> Restart <<<`);
                    this.startup();
                }
            }), 1000);
        } catch (e) {
            this.debug && console.error(e);
        }
    };

    resetErrorChecking() {
        this.tryToRestart = false;
        this.prevEndTime = 0;
        this.lastWsTime = 0;
        this.futureTsErr = 0;
        this.pastTsErr = 0;
        this.noMsgErr = 0;
        this.noConnErr = 0;
    };

    errorChecker() {
        try {
            let tsNow = Date.now();
            if (this.lastWsTime > 0) {
                if ((tsNow - this.lastWsTime) > NO_MSG_THRESHOLD) {
                    this.noMsgErr++;
                    this.debug && console.warn(`[${this.target}#${this.startupCount}] No message is received via WS (${this.noMsgErr})`);
                } else {
                    this.noMsgErr = 0;
                }
            }
            if (this.debug) {
                if (this.lastCalculateTime <= 0) {
                    this.lastCalculateTime = tsNow;
                } else if ((tsNow - this.lastCalculateTime) >= (this.debug > 1 ? 1000 : 5000)) {
                    this.lastCalculateTime = tsNow;
                    this.streamInfo.logDataRate()
                }
            }
        } catch (e) {
            this.debug && console.error(e);
        }

        // If an error occurs, restart player
        if (this.active) {
            (this.debug > 1) && console.log(`[${this.target}#${this.startupCount}] errorChecker: futureTsErr=${this.futureTsErr}, oldTsErr=${this.pastTsErr}, noMsgErr=${this.noMsgErr}, noConnErr=${this.noConnErr}`);
            if (this.futureTsErr > 0 || this.pastTsErr > PAST_TS_THRESHOLD || this.noMsgErr > 0 || this.noConnErr > 0) {
                this.debug && console.warn(`[${this.target}#${this.startupCount}] Error occurred; try to restart player`);
                this.restart();
                        } else {
                window.setTimeout((() => this.errorChecker()), 200);
            }
        }
    };

    resetDataRateCalculators() {
        this.streamInfo.reset()
        this.lastCalculateTime = 0;
    };
}

function Utf8ArrayToStr(array) {
    if (window.TextDecoder) {
        return new TextDecoder("utf-8").decode(array);
    } else {
        var out, i, len, c;
        var char2, char3;
        out = '';
        len = array.length;
        i = 0;
        while (i < len) {
            c = array[i++];
            switch (c >> 4) {
                case 7:
                    out += String.fromCharCode(c);
                    break;
                case 13:
                    char2 = array[i++];
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    char2 = array[i++];
                    char3 = array[i++];
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                        ((char2 & 0x3F) << 6) |
                        ((char3 & 0x3F) << 0));
                    break;
            }
        }
        return out;
    }
};

function rateFunction(currentBufferedTime, targetBufferedTime, tolerance) {
    var rate = 1.0;
    var upperBound = targetBufferedTime + tolerance;
    var lowerBound = (targetBufferedTime - tolerance) > 0.3 ? (targetBufferedTime - tolerance) : 0.3; //minimum
    if (currentBufferedTime > upperBound) {
        //buffer too much
        rate = Math.pow(1.15, (currentBufferedTime - upperBound) * 4);
        if (rate > 3.0) {
            rate = 3.0;
        }
    }
    else if (currentBufferedTime < lowerBound) {
        //buffer too less
        rate = Math.pow(1.5, (currentBufferedTime - lowerBound));
        if (rate < 0.8) {
            rate = 0.8;
        }
    }
    rate = Math.round(rate * 1000) / 1000.0;
    return rate;
}

class StreamInfo {
    constructor() {
        this.videoCodec = null
        this.audioCodec = null
        this.hasExtraMeta = false
        this.wsDataRate = new DataRateCalculator(1000, 3000, 30)
        this.vsDataRate = new DataRateCalculator(1e5, 3e5, 30, false)
        this.vsFrameRate = new DataRateCalculator(1e5, 3e5, 30, false)
        this.viDataRate = new DataRateCalculator(1e5, 3e5, 30, false)
        this.viFrameRate = new DataRateCalculator(1e5, 3e5, 30, false)
        this.vrDataRate = new DataRateCalculator(1000, 3000, 30)
        this.vrFrameRate = new DataRateCalculator(1000, 3000, 30)
        this.asDataRate = new DataRateCalculator(1e5, 3e5, 30, false)
        this.asFrameRate = new DataRateCalculator(1e5, 3e5, 30, false)
        this.arDataRate = new DataRateCalculator(1000, 3000, 30)
        this.arFrameRate = new DataRateCalculator(1000, 3000, 30)

        this.dataRateSyncer = new DataRateCalculatorSyncer(
            { name: "vrDataRate", calculator: this.vrDataRate },
            { name: "vsDataRate", calculator: this.vsDataRate },
            { name: "vsFrameRate", calculator: this.vsFrameRate },
            { name: "viDataRate", calculator: this.viDataRate },
            { name: "viFrameRate", calculator: this.viFrameRate },
            { name: "vrFrameRate", calculator: this.vrFrameRate },
            { name: "asDataRate", calculator: this.asDataRate },
            { name: "asFrameRate", calculator: this.asFrameRate },
            { name: "arDataRate", calculator: this.arDataRate },
            { name: "arFrameRate", calculator: this.arFrameRate })
    }

    get hasVideo() {
        return this.videoCodec != null
    }

    get hasAudio() {
        return this.audioCodec != null
    }

    get codecs() {
        return [(this.videoCodec && this.videoCodec.simpleName) || 'None', (this.audioCodec && this.audioCodec.simpleName) || 'None'].join(' / ')
    }

    get dataRxBitrate() {
        return this.getDataRate(this.wsDataRate, 8)
    }

    get videoSrcBitrate() {
        return this.hasExtraMeta ? this.getDataRate(this.vsDataRate, 8) : null
    }

    get videoSrcFramerate() {
        return this.hasExtraMeta ? this.getDataRate(this.vsFrameRate) : null
    }

    get videoInBitrate() {
        return this.hasExtraMeta ? this.getDataRate(this.viDataRate, 8) : null
    }

    get videoInFramerate() {
        return this.hasExtraMeta ? this.getDataRate(this.viFrameRate) : null
    }

    get videoRxBitrate() {
        return this.hasExtraMeta ? this.getDataRate(this.vrDataRate, 8) : null
    }

    get videoRxFramerate() {
        return this.hasExtraMeta ? this.getDataRate(this.vrFrameRate) : null
    }

    get audioSrcBitrate() {
        return this.hasExtraMeta ? this.getDataRate(this.asDataRate, 8) : null
    }

    get audioSrcFramerate() {
        return this.hasExtraMeta ? this.getDataRate(this.asFrameRate) : null
    }

    get audioRxBitrate() {
        return this.hasExtraMeta ? this.getDataRate(this.arDataRate, 8) : null
    }

    get audioRxFramerate() {
        return this.hasExtraMeta ? this.getDataRate(this.arFrameRate) : null
    }

    get dataRxBitrateHistory() {
        return this.getDataRateHistory(this.wsDataRate, 8)
    }

    get videoSrcBitrateHistory() {
        return this.hasExtraMeta ? this.getDataRateHistory(this.vsDataRate, 8) : null
    }

    get videoSrcFramerateHistory() {
        return this.hasExtraMeta ? this.getDataRateHistory(this.vsFrameRate) : null
    }

    get videoInBitrateHistory() {
        return this.hasExtraMeta ? this.getDataRateHistory(this.viDataRate, 8) : null
    }

    get videoInFramerateHistory() {
        return this.hasExtraMeta ? this.getDataRateHistory(this.viFrameRate) : null
    }

    get videoRxBitrateHistory() {
        return this.hasExtraMeta ? this.getDataRateHistory(this.vrDataRate, 8) : null
    }

    get videoRxFramerateHistory() {
        return this.hasExtraMeta ? this.getDataRateHistory(this.vrFrameRate) : null
    }

    get videoRxSrcCorrelation() {
        const correl = this.getCorrelationHistory(this.vrDataRate, this.vsDataRate, 1)
        if (correl && correl.length > 0) {
            return correl[0]
        }
    }

    get videoRxInCorrelation() {
        const correl = this.getCorrelationHistory(this.vrDataRate, this.viDataRate, 1)
        if (correl && correl.length > 0) {
            return correl[0]
        }
    }

    get videoRxSrcCorrelHistory() {
        return this.getCorrelationHistory(this.vrDataRate, this.vsDataRate)
    }

    get videoRxInCorrelHistory() {
        return this.getCorrelationHistory(this.vrDataRate, this.viDataRate)
    }

    updateCodec(codecParameter) {
        if (typeof codecParameter === 'string') {
            codecParameter.split(',').forEach((codec) => {
                const codecInfo = new CodecInfo(codec)
                if (codecInfo.isVideo) this.videoCodec = codecInfo
                else if (codecInfo.isAudio) this.audioCodec = codecInfo
            }, this)
        }
    }

    reset() {
        this.videoCodec = null
        this.audioCodec = null
        this.hasExtraMeta = false
        this.resetDataRate()
    }

    resetDataRate() {
        this.wsDataRate.reset()
        this.vsDataRate.reset()
        this.vsFrameRate.reset()
        this.vrDataRate.reset()
        this.vrFrameRate.reset()
        this.asDataRate.reset()
        this.asFrameRate.reset()
        this.arDataRate.reset()
        this.arFrameRate.reset()
    };

    recordDataReceived(dataBytes, timestamp) {
        const tsReceived = timestamp || Date.now()
        this.wsDataRate.recordDataCount(tsReceived, dataBytes)
    }

    _recordMetaReceivedV1(jsonMeta, timestamp) {
        const tsReceived = timestamp || Date.now()
        const { FragInfo } = jsonMeta || {}
        const { PktInfos } = FragInfo || {}

        if (PktInfos) {
            this.hasExtraMeta = true
            PktInfos.forEach((pktInfo) => {
                const { VBytes, ABytes, Time } = pktInfo
                if (VBytes) {
                    if (Time) {
                        this.vsDataRate.recordDataCount(Time, VBytes);
                        this.vsFrameRate.recordDataCount(Time, 1);
                    }
                    this.vrDataRate.recordDataCount(tsReceived, VBytes);
                    this.vrFrameRate.recordDataCount(tsReceived, 1);
                } else if (ABytes) {
                    if (Time) {
                        this.asDataRate.recordDataCount(Time, ABytes);
                        this.asFrameRate.recordDataCount(Time, 1);
                    }
                    this.arDataRate.recordDataCount(tsReceived, ABytes);
                    this.arFrameRate.recordDataCount(tsReceived, 1);
                }
            }, this)
        }
    }

    _recordMetaReceivedV2(jsonMeta, timestamp) {
        const tsReceived = timestamp || Date.now()
        const { ts: fragTs, frag: fragInfo } = jsonMeta || {}
        const { ts: frameTs, len: frameLen, type: frameType } = fragInfo || {}

        if (fragTs && frameTs && frameLen && frameType) {
            this.hasExtraMeta = true
            frameType.forEach((type, i) => {
                let slotChanged = false
                if ((type & 0x01) == 0) { // video
                    if (i < frameLen.length) {
                        // master calculator record data count first
                        slotChanged = this.dataRateSyncer.recordDataCount('vrDataRate', tsReceived, frameLen[i])

                        this.dataRateSyncer.recordDataCount('viDataRate', fragTs, frameLen[i])
                        this.dataRateSyncer.recordDataCount('viFrameRate', fragTs, 1)
                        this.dataRateSyncer.recordDataCount('vrFrameRate', tsReceived, 1)

                        if (i < frameTs.length) {
                            this.dataRateSyncer.recordDataCount('vsDataRate', frameTs[i], frameLen[i])
                            this.dataRateSyncer.recordDataCount('vsFrameRate', frameTs[i], 1)
                        }
                    }
                } else if ((type & 0x01) == 1) { // audio
                    if (i < frameLen.length) {
                        this.dataRateSyncer.recordDataCount('arDataRate', tsReceived, frameLen[i])
                        this.dataRateSyncer.recordDataCount('arFrameRate', tsReceived, 1)

                        if (i < frameTs.length) {
                            this.dataRateSyncer.recordDataCount('asDataRate', frameTs[i], frameLen[i])
                            this.dataRateSyncer.recordDataCount('asFrameRate', frameTs[i], 1)
                        }
                    }
                }

                // if (slotChanged) {
                //     this.logDataRate()
                // }
            }, this)
        }
    }

    recordMetaReceived(jsonMeta, timestamp) {
        const { Ver } = jsonMeta || {}
        if (Ver && Ver.startsWith('0.1')) {
            this._recordMetaReceivedV1(jsonMeta, timestamp)
        } else {
            this._recordMetaReceivedV2(jsonMeta, timestamp)
        }
    }

    getDataRate(dataRateCalculator, scale = 1) {
        return (dataRateCalculator instanceof DataRateCalculator)
            ? { current: dataRateCalculator.currentRate * scale, average: dataRateCalculator.averageRate * scale }
            : null
    }

    getDataRateHistory(dataRateCalculator, scale = 1, count = 5) {
        return (dataRateCalculator instanceof DataRateCalculator)
            ? dataRateCalculator.getRecentRates(count, true).map((rate) => rate * scale)
            : null
    }

    logDataRate() {
        const tsNow = Date.now()

        console.log(`[${tsNow}] WS: dr=${this.wsDataRate.currentRate.toFixed(0)}/${this.wsDataRate.averageRate.toFixed(0)}`)

        const vsCurrentDataRate = this.vsDataRate.currentRate
        const vsCurrentFrameRate = this.vsFrameRate.currentRate
        const viCurrentDataRate = this.viDataRate.currentRate
        const viCurrentFrameRate = this.viFrameRate.currentRate
        const vrCurrentDataRate = this.vrDataRate.currentRate
        const vrCurrentFrameRate = this.vrFrameRate.currentRate

        const vsPrevDataRate = this.vsDataRate.getRate(-1, true)
        const vsPrevFrameRate = this.vsFrameRate.getRate(-1, true)
        const viPrevDataRate = this.viDataRate.getRate(-1, true)
        const viPrevFrameRate = this.viFrameRate.getRate(-1, true)
        const vrPrevDataRate = this.vrDataRate.getRate(-1, true)
        const vrPrevFrameRate = this.vrFrameRate.getRate(-1, true)

        const vrsDataRateBias = (vrPrevDataRate - vsPrevDataRate) / vsPrevDataRate
        const vrsFrameRateBias = (vrPrevFrameRate - vsPrevFrameRate) / vsPrevFrameRate
        const vriDataRateBias = (vrPrevDataRate - viPrevDataRate) / viPrevDataRate
        const vriFrameRateBias = (vrPrevFrameRate - viPrevFrameRate) / viPrevFrameRate

        const vrsCorrelation = this.videoRxSrcCorrelation
        const vriCorrelation = this.videoRxInCorrelation

        console.log(`[${tsNow}] VS: dr=${vsCurrentDataRate.toFixed(0)}/${this.vsDataRate.averageRate.toFixed(0)} fr=${vsCurrentFrameRate.toFixed(1)}/${this.vsFrameRate.averageRate.toFixed(1)}`)
        console.log(`[${tsNow}] VI: dr=${viCurrentDataRate.toFixed(0)}/${this.viDataRate.averageRate.toFixed(0)} fr=${viCurrentFrameRate.toFixed(1)}/${this.viFrameRate.averageRate.toFixed(1)}`)
        console.log(`[${tsNow}] VR: dr=${vrCurrentDataRate.toFixed(0)}/${this.vrDataRate.averageRate.toFixed(0)} fr=${vrCurrentFrameRate.toFixed(1)}/${this.vrFrameRate.averageRate.toFixed(1)}`)
        console.log(`[${tsNow}] VR-S: dr=${vrPrevDataRate.toFixed(0)}/${vsPrevDataRate.toFixed(0)} fr=${vrPrevFrameRate.toFixed(1)}/${vsPrevFrameRate.toFixed(1)} drb=${vrsDataRateBias.toFixed(3)} frb=${vrsFrameRateBias.toFixed(3)} correl=${vrsCorrelation ? vrsCorrelation.toFixed(3) : ''}`)
        console.log(`[${tsNow}] VR-I: dr=${vrPrevDataRate.toFixed(0)}/${viPrevDataRate.toFixed(0)} fr=${vrPrevFrameRate.toFixed(1)}/${viPrevFrameRate.toFixed(1)} drb=${vriDataRateBias.toFixed(3)} frb=${vriFrameRateBias.toFixed(3)} correl=${vriCorrelation ? vriCorrelation.toFixed(3) : ''}`)

        console.log(`[${tsNow}] AS: dr=${this.asDataRate.currentRate.toFixed(0)}/${this.asDataRate.averageRate.toFixed(0)} fr=${this.asFrameRate.currentRate.toFixed(1)}/${this.asFrameRate.averageRate.toFixed(1)}`)
        console.log(`[${tsNow}] AR: dr=${this.arDataRate.currentRate.toFixed(0)}/${this.arDataRate.averageRate.toFixed(0)} fr=${this.arFrameRate.currentRate.toFixed(1)}/${this.arFrameRate.averageRate.toFixed(1)}`)
    }

    getCorrelationHistory(calculator1, calculator2, historyNum = 5, count = 5) {
        if (calculator1 instanceof DataRateCalculator && calculator2 instanceof DataRateCalculator && historyNum > 0 && count > 0) {
            const arr1 = calculator1.getRecentRates(count + historyNum)
            const arr2 = calculator2.getRecentRates(count + historyNum)
            let start = (arr1[arr1.length - 1] == 0) ? 0 : 1
            const correlHistory = []
            for (let n = 0; n < historyNum; n++, start++) {
                correlHistory.push(this.calculateCorrelation(arr1.slice(start, start + count), arr2.slice(start, start + count)))
            }
            return correlHistory
        }
    }

    calculateCorrelation(arr1, arr2) {
        if (arr1.length === arr2.length && arr1.length !== 0) {
            const mean1 = arr1.reduce((acc, val) => acc + val, 0) / arr1.length
            const mean2 = arr2.reduce((acc, val) => acc + val, 0) / arr2.length

            let numerator = 0
            let denominator1 = 0
            let denominator2 = 0

            for (let i = 0; i < arr1.length; i++) {
                numerator += (arr1[i] - mean1) * (arr2[i] - mean2)
                denominator1 += Math.pow(arr1[i] - mean1, 2)
                denominator2 += Math.pow(arr2[i] - mean2, 2)
            }

            const denominator = Math.sqrt(denominator1) * Math.sqrt(denominator2)

            return numerator / denominator
        }
    }
}

// Refer to https://developer.mozilla.org/en-US/docs/Web/Media/Formats/codecs_parameter#iso_base_media_file_format_mp4_quicktime_and_3gp
class CodecInfo {
    constructor(codec) {
        this.codec = String(codec)
        this.isVideo = false
        this.isAudio = false
        this.simpleName = this.codec
        this.fullName = this.codec

        const components = this.codec.split('.')
        if (components.length > 0) {
            this.fourccID = components[0].trim().toLowerCase()

            switch (this.fourccID) {
                case "avc1":
                    this.isVideo = true
                    this.simpleName = "H264"
                    this.fullName = this.simpleName
                    break
                case "hev1":
                    this.isVideo = true
                    this.simpleName = "H265"
                    this.fullName = this.simpleName
                    break
                case "mp4a":
                    this.isAudio = true
                    if (components.length > 1) {
                        const objectType = parseInt(components[1], 16)
                        if (objectType === 0x40 && components.length > 2) {
                            const audioObjectType = parseInt(components[2], 10)
                            if (audioObjectType >= 1 && audioObjectType <= 6) {
                                this.simpleName = 'AAC'
                                this.fullName = this.simpleName
                                switch (audioObjectType) {
                                    case 1:
                                        this.fullName = 'AAC Main'
                                        break
                                    case 2:
                                        this.fullName = 'AAC LC'
                                        break
                                    case 3:
                                        this.fullName = 'AAC SSR'
                                        break
                                    case 4:
                                        this.fullName = 'AAC LTP'
                                        break
                                    case 5:
                                        this.fullName = 'AAC SBR'
                                        break
                                    case 6:
                                        this.fullName = 'AAC Scalable'
                                        break
                                }
                            }
                        }
                    }
                    break
            }
        }
    }

    toString() {
        return this.fullName
    }
}

class DataRateCalculator {
    constructor(timeScale, slotDuration, maxSlotNumber, useLocalTime = true) {
        this.timeScale = timeScale; // If time is seconds, timeScale is 1; if time is milliseconds, timeScale is 1000
        this.slotDuration = slotDuration;
        this.maxSlotNumber = maxSlotNumber;
        this.useLocalTime = (useLocalTime != false) // If the useLocalTime parameter is omitted, the default value "true" is used
        this.maxIdleTime = (this.useLocalTime) ? this.slotDuration : -1
        this.reset();
    }

    reset() {
        this.slots = [{ startTime: 0, endTime: 0, dataCount: 0 }];
        this.currentSlotIndex = 0;
    }

    get isEmpty() {
        return (this.slots.length == 1 && this.slots[0].endTime <= this.slots[0].startTime)
    }

    get localTimeNow() {
        return Date.now() * (this.timeScale / 1000)
    }

    recordDataCount(ts, dataCount, forceSlotChange = false) {
        let slot = this.slots[this.currentSlotIndex];
        let slotChanged = false;
        if (slot.startTime <= 0) {
            slot.startTime = ts;
            slot.endTime = ts;
            slot.dataCount = 0;
        } else {
            const tsReset = (ts < slot.endTime)
            if (!tsReset) {
                slot.endTime = ts;
                slot.dataCount += dataCount;
            } else {
                // Adjust time instead of automatically changing slots
                slot.startTime = ts - (slot.endTime - slot.startTime)
                slot.endTime = ts
            }

            if (forceSlotChange || (this.slotDuration >= 0 && (ts - slot.startTime) >= this.slotDuration)) {
                this.currentSlotIndex = (this.currentSlotIndex + 1) % this.maxSlotNumber;
                slotChanged = true;

                if (this.currentSlotIndex >= this.slots.length) {
                    this.slots[this.currentSlotIndex] = { startTime: 0, endTime: 0, dataCount: 0 };
                }
                slot = this.slots[this.currentSlotIndex];
                slot.startTime = ts;
                slot.endTime = ts;
                slot.dataCount = 0;
            }
        }

        return slotChanged;
    }

    getRate(slotIndex, relative = false) {
        if (relative == true) {
            slotIndex = (this.currentSlotIndex + slotIndex + this.maxSlotNumber) % this.maxSlotNumber
        }
        if (slotIndex >= 0 && slotIndex < this.slots.length) {
            const slot = this.slots[slotIndex];
            const duration = slot.endTime - slot.startTime;
            return (duration > 0) ? (slot.dataCount * this.timeScale / duration) : 0;
        }
        return 0;
    }

    get currentRate() {
        const slot = this.slots[this.currentSlotIndex];
        let duration = slot.endTime - slot.startTime;
        let dataCount = slot.dataCount
        if (duration == 0) {
            const prevSlotIndex = (this.currentSlotIndex - 1 + this.maxSlotNumber) % this.maxSlotNumber;
            if (prevSlotIndex < this.slots.length) {
                duration += this.slots[prevSlotIndex].endTime - this.slots[prevSlotIndex].startTime
                dataCount += this.slots[prevSlotIndex].dataCount
            }
        }
        if (this.useLocalTime) {
            const idleTime = this.localTimeNow - slot.endTime
            if (this.maxIdleTime >= 0 && idleTime > this.maxIdleTime) {
                return 0
            }
            duration += idleTime
        }
        return (duration > 0) ? (dataCount * this.timeScale / duration) : 0;
    }

    get averageRate() {
        let dataCount = 0;
        let duration = 0;
        this.slots.forEach((slot) => {
            if (slot.dataCount > 0) {
                dataCount += slot.dataCount;
                duration += (slot.endTime - slot.startTime);
            }
        });

        return (duration > 0) ? (dataCount * this.timeScale / duration) : 0;
    }

    getRecentRates(count, updateCurrent = false) {
        const rates = [];
        for (let n = count; n > 0; n--) {
            const slotIndex = (this.currentSlotIndex - n + 1 + this.maxSlotNumber) % this.maxSlotNumber;
            rates.push((updateCurrent && slotIndex == this.currentSlotIndex) ? this.currentRate : this.getRate(slotIndex));
        }
        return rates;
    }
};

class DataRateCalculatorSyncer {
    constructor({ name, calculator }, ...slaves) {
        this.calculatorMap = new Map()
        this.calculatorMap.set(name, { calculator: calculator, master: true })
        const maxSlotNumber = calculator.maxSlotNumber
        slaves.forEach(({ name, calculator }) => {
            if (name && calculator) {
                this.calculatorMap.set(name, { calculator: calculator })
                calculator.slotDuration = -1
                calculator.maxSlotNumber = maxSlotNumber
            }
        }, this)

        this.reset()
    }

    reset() {
        this.calculatorMap.forEach(({ calculator }) => { calculator.reset() }, this)
    }

    recordDataCount(name, ts, dataCount) {
        const currentItem = this.calculatorMap.get(name)
        const { calculator, master, forceSlotChange } = currentItem || {}
        let slotChanged = false
        if (calculator) {
            if (master) {
                slotChanged = calculator.recordDataCount(ts, dataCount)
                if (slotChanged) {
                    this.calculatorMap.forEach((v) => {
                        if (!v.master) v.forceSlotChange = true
                    }, this)
                }
            } else {
                if (forceSlotChange) {
                    slotChanged = calculator.recordDataCount(ts, dataCount, true)
                    currentItem.forceSlotChange = false
                } else {
                    slotChanged = calculator.recordDataCount(ts, dataCount)
                }
            }
        }
        return slotChanged
    }
}

export default Mse